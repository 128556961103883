<template>
  <div class="container flex-column flex-main-center">
    <div class="title">校园空调管理系统</div>
    <input type="text" name="ac" v-model.trim="formData.account" maxlength="20" placeholder="用户名"
           autocomplete="off"/>
    <input type="password" name="password" v-model="formData.password" maxlength="20" placeholder="密码"
           autocomplete="new-password"/>
    <div class="flex flex-cross-center" v-if="captchaUrl">
      <input type="text" name="cap" v-model="formData.captcha" class="flex-auto"
             maxlength="6" placeholder="验证码" autocomplete="off" style="width:1px"/>
      <img :src="captchaUrl" class="captcha flex-none" @click="refreshCaptcha"/>
    </div>
    <button type="button" class="submit" :class="{loading}" @click="onLogin">登录</button>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data () {
      return {
        loading: false,
        captchaUrl: '',
        isReadOnly: true,
        formData: {
          randomId: '',
          captcha: '',
          account: '',
          password: ''
        }
      }
    },
    mounted () {
      this.$nextTick(() => this.resetForm())
    },
    methods: {
      onFocus () {
        this.isReadOnly = false
      },
      onBlur () {
        this.isReadOnly = true
      },
      resetForm () {
        this.formData = {
          randomId: '',
          captcha: '',
          account: localStorage.getItem('account') || '',
          password: ''
        }
      },
      refreshCaptcha () {
        this.formData.randomId = Math.random()
        this.captchaUrl = `${process.env.VUE_APP_BASE_API}/com/captcha/getImageCaptcha/${this.formData.randomId}`
      },
      onLogin () {
        if (this.loading) {
          return
        }
        if (!this.formData.account || !this.formData.password) {
          this.$toast.show('账号或密码填写不完整')
          return
        }
        if (this.captchaUrl && !this.formData.captcha) {
          this.$toast.show('请填写验证码')
          return
        }
        this.loading = true
        this.$axios.post('/h5/user/authenticate', this.formData)
          .then(res => {
            localStorage.setItem('account', this.formData.account)
            this.$store.dispatch('Login', res).then(() => {
              // if (this.$store.getters.accountType === 'PLATFORM') {
              //     this.$store.dispatch('SetTenants', resp)
              // } else if (this.$store.getters.accountType === 'TENANT') {

              // }
              this.$axios.post('/h5/user/info').then(res => {
                this.$store.dispatch('SetInfo', res).then(() => {
                  if (this.$route.query.lastPath) {
                    this.$router.back()
                  } else {
                    this.$router.replace('/')
                  }
                })
              })
              .catch(() => {
                this.loading = false
              })
            })
          })
          .catch(response => {
            // console.log('catch', response)
            if (response.status === 9008) {
              this.refreshCaptcha()
            }
            this.formData.password = ''
            this.$toast.show(response.msg)
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped lang="stylus">
.container
  padding 0 75rpx
  font(48,64,#fff)
  text-align center
input
  background: rgba(12,12,58,0.45);
  border-radius: 20rpx;
  padding 26rpx 32rpx
  font(28,44,rgba(255,255,255,0.65))
  border none
  outline none
  margin-bottom 40rpx
  letter-spacing 0.1em
.title
  margin-bottom 40rpx
.loading
  opacity 0.7
.captcha
  width: 200rpx
  height: 80rpx
  pointer-events auto
  margin-left 40rpx
  margin-bottom 40rpx
</style>
